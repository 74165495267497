import { Button } from "components/Button"
import Layout, { Container, Section } from "components/Layout"
import SEO from "components/Seo"

import type { NextPage } from "next"

const Error404: NextPage = () => {
  return (
    <>
      <SEO
        pageTitle="404: Page not found"
        titleTag={`404 | ${process.env.NEXT_PUBLIC_SITE_NAME}`}
        metaDescription="Oops. You have reached our 404 error page and this page no longer exists if it ever existed at all."
      />
      <Layout>
        <Section className="pb-11">
          <Container className="text-center">
            <h1>404</h1>
            <p>Oops, page not found</p>
            <Button href="/" title="Link to homepage">
              Go home
            </Button>
          </Container>
        </Section>
      </Layout>
    </>
  )
}
export default Error404
